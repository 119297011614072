import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const Twitter = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className="cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_646_6512)">
        <path
          d="M5.0134 14.4424C11.0256 14.4424 14.315 9.4601 14.315 5.1408C14.315 5.00074 14.3119 4.85756 14.3056 4.71749C14.9455 4.25474 15.4977 3.68156 15.9363 3.02489C15.3404 3.29003 14.7076 3.46319 14.0597 3.53846C14.7419 3.12954 15.2527 2.48715 15.4974 1.73037C14.8557 2.11071 14.1538 2.37901 13.422 2.52376C12.9289 1.99982 12.2769 1.65291 11.5669 1.53667C10.8569 1.42042 10.1283 1.54132 9.49388 1.88066C8.85945 2.22 8.35447 2.75889 8.05702 3.41401C7.75958 4.06912 7.68623 4.80399 7.84832 5.50497C6.54882 5.43976 5.27753 5.10219 4.11687 4.51413C2.9562 3.92608 1.93207 3.10067 1.11087 2.09143C0.693494 2.81104 0.565776 3.66257 0.753675 4.47296C0.941573 5.28335 1.43099 5.99179 2.12245 6.4543C1.60334 6.43782 1.0956 6.29806 0.641187 6.04656V6.08702C0.640722 6.84219 0.901794 7.57423 1.38002 8.15868C1.85825 8.74313 2.52413 9.14393 3.26445 9.29295C2.78358 9.42452 2.27888 9.44369 1.78941 9.34898C1.99832 9.99844 2.40477 10.5665 2.95205 10.9738C3.49932 11.3811 4.16012 11.6074 4.84221 11.6211C3.68422 12.5308 2.25377 13.0241 0.781252 13.0218C0.520112 13.0214 0.25923 13.0054 0 12.9739C1.49592 13.9336 3.23609 14.4433 5.0134 14.4424Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_646_6512">
          <rect width="15.9363" height="15.9363" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default function About({ data: { sanityAbout } }) {
  const [hover, setHover] = useState(null);

  const title = sanityAbout?.title?.split("stake");
  const stakeIndex = sanityAbout?.title?.indexOf("stake");

  return (
    <Layout>
      <div className="relative">
        <div className="absolute top-48 right-0 bottom-0 left-0 -z-10">
          <StaticImage
            src="../images/purple-center.webp"
            alt="logo"
            className="centerbg"
          />
        </div>
        <div className="max-w-[1366px] mx-auto px-5 lg:px-0">
          {/* Hero */}
          <div className="pt-[105px] mx-auto max-w-[850px]">
            <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white text-center">
              {title.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
              {stakeIndex !== -1 && (
                <span className={"txt-gradient-yellow"}>stake</span>
              )}
            </p>
            <p className="font-normal leading-6 text-base text-white mt-[17px]">
              {sanityAbout?.content?.map((item, index) => (
                <Fragment key={index}>
                  <span className="block">{item?.children[0]?.text}</span>
                  <br />
                </Fragment>
              ))}
            </p>
          </div>
          <div className="mt-[122px] mx-auto max-w-[900px]">
            <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white text-center">
              {sanityAbout?.teamMembers?.title}
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-5 gap-y-16 mt-[44px]">
              {sanityAbout?.teamMembers?.teamList.map((item, index) => (
                <div
                  className="rounded-[12.54px] bg-[#2E2E2E]/[0.38] flex flex-col items-center"
                  key={index}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                >
                  <div className="h-[180px] w-[180px] mt-5 mx-[15px] image-circle">
                    <GatsbyImage
                      image={
                        hover === index
                          ? item?.hoverPhoto?.asset?.gatsbyImageData
                          : item?.photo?.asset?.gatsbyImageData
                      }
                      alt="team-photo"
                      className="rounded-full"
                    />
                  </div>
                  <p className="font-medium leading-[30px] mt-[13.32px] text-[20px] text-white text-center">
                    {item.title}
                  </p>
                  <div className="mt-[9.68px] mb-[21px] flex items-center">
                    <a href={item.twitter} target="_blank">
                      <Twitter />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[400px]" />
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityAbout {
      title
      content {
        children {
          text
        }
      }
      teamMembers {
        title
        teamList {
          twitter
          title
          photo {
            asset {
              gatsbyImageData(fit: FILL, placeholder: BLURRED)
            }
          }
          hoverPhoto {
            asset {
              gatsbyImageData(fit: FILL, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
